import styled from "@emotion/styled"
import { colors, animationDuration, breakpoints } from "../styles"

export const ServicesOfferedContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
  margin-top: 2rem;
  @media (min-width: ${breakpoints.phablet}px) {
    max-height: 600px;
    max-width: 800px;
  }
`

export const ServicesOfferedWrapper = styled.div`
  text-align: center;
  padding: 0 2rem;
`

export const ServiceWrapper = styled.div`
  display: flex;
  cursor: pointer;
  width: 50%;
  height: 50%;
  transition-duration: 0.3s;
  &:hover {
    transform: scale(1.1);
    box-shadow: 0px 6px 20px 0px rgba(0,0,0,.06);
    z-index: 8;
  };
  @media (max-width: ${breakpoints.phablet}px) {
     width: 100%;
     margin-bottom: 30px;
  }
`

export const WrapperColumn = styled.div`
  @media (max-width: ${breakpoints.tablet}px) {
     display: flex;
     flex-direction: column-reverse;
  }
`

export const ImageWrapper = styled.div`
  width: 50%;
  overflow: hidden;
`
export const TitleWrapper = styled.div`
  display: flex;
  text-transform: uppercase;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background: white;
  justify-content: center;
  width: 50%;
  font-family: Lora;
  font-size: 1rem;
  font-weight: 500;
  padding: 20px;
 
`

export const TitleService = styled.span`
  overflow: hidden;
  transition-duration: 0.3s;
  &.isHover {
  font-size: 1.2rem;
  }
`

export const TitleContainer = styled.span`
  font-size: 1.3rem;
`

export const ImageService = styled.img`
  width: 100%;
  height: 100%;
  transition-duration: 0.3s;
  &.isHover {
    transform: scale(1.3);
  }
`

export const ButtonForm = styled.button`
  display: flex;
  font-family: Lora;
  cursor: pointer;
  border: 0;
  margin: auto;
  margin-top: 50px;
  padding: 10px 13px;
  background: ${colors.softBeige};
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  color: ${colors.darkWaskoll};
  outline: none;
  font-size: 1rem;
  width: 200px;
  transition-duration: 0.2s;
  &:hover{
    background: ${colors.beige};
    color: black;
  }
`

export const Divider = styled.div`
  height: 2px;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 5px;
  width: 35px;
  background: ${colors.redWaskoll};
`

