import styled from "@emotion/styled"
import { breakpoints, colors, dimensions } from "../styles"

export const WrapperContent = styled.div`
  top: 11vh;
  color: white;
  left: 6vw;
  text-align: left;
  position: absolute;
  bottom: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const WrapperDescription = styled.div`
  top: 20vh;
  color: white;
  left: 10vw;
  height: 50px;
  position: absolute;
  bottom: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const WrapperSlider = styled.div`
  width: 100%;
  margin: auto;
  max-height: 80vh;
  overflow: hidden;
`

export const ImageSliderDesktop = styled.img`
  width: 100%;
  height: auto;
`

export const ImageSliderMobile = styled.img`
  width: 100%;
  height: auto;
`

export const TitleSlide = styled.div`
  font-size: 2.5rem;
`

export const DescriptionSlide = styled.div`
  font-size: 1.8rem;
`

export const ButtonSlide = styled.button`
  margin-top: 2rem;
  background: white;
  background: none;
  border: 1px solid white;
  color: white;
  text-transform: uppercase;
  font-family: 'Lato';
  border-radius: .5rem;
  outline: none;
  padding: 1rem;
  cursor: pointer;
`