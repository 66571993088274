import styled from "@emotion/styled"
import { colors, animationDuration, breakpoints } from "../styles"

export const FeaturedCollectionWrapper = styled.div`
  width: 100%;
  padding-bottom: 50px;
  display: flex;
  align-item: center;
  justify-content: center;
  padding: 2rem;
  @media (max-width: ${breakpoints.desktop}px) {
    flex-direction: column;
  }
  @media (max-width: ${breakpoints.tablet}px) {
     display: none;
  }
`

export const CollectionWrapper = styled.div`
  width: 100%;
  display: flex;
  align-item: center;
  justify-content: center;
  max-width: 800px;
  margin: auto;
`

export const DescriptionWrapper = styled.div`
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: ${breakpoints.desktop}px) {
    margin-top: 30px;
    padding: 0px;
  }
`

export const WrapperLeft = styled.div`
  width: 100%;
  min-width: 360px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-item: center;
  justify-content: center;
`

export const WrapperRight = styled.div`
  position: relative;
  margin-left: 25px;
  display: flex;
  align-item: center;
  justify-content: space-between;
`

export const MetaCollection = styled.div`
  display: flex;
  background: ${colors.softBeige};
  min-height: 165px;
  border-radius: .5rem;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding: 0 1rem;
  @media (max-width: ${breakpoints.tablet}px) {
    padding: 0 ;
    padding-bottom: 18px;
  }
`

export const TitleCollection = styled.span`
  font-size: 1.3rem;
  font-family: Lora;
`

export const ShortDescriptionCollection = styled.h1`
  font-size: 1rem;
  color: black;
  font-weight: 400;
  font-style: italic;
  text-align: center;
`

export const DescriptionCollection = styled.h1`
  font-size: 1rem;
  white-space: pre-wrap;
  color: black;
  font-weight: 400;
  font-style: italic;
  text-align: center;
`

export const WrapperImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const WrapperImageCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
`

export const ImageThumb = styled.div`
  min-height: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${breakpoints.tablet}px) {
    margin-right: 0px;
  }
`

export const ImageThumbCol = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${breakpoints.tablet}px) {
    margin-right: 0px;
  }
`

export const ImagePreviewWrapper = styled.div`
  width: 200px;
  height: 200px;
`

export const ImageProduct = styled.img`
  max-width: 150px;
`

export const ImageProductPreview = styled.img`
  max-width: 200px;
`

 export const ImagePreview = styled.div`
  width: 250px;
  height: 250px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const WrapperPreview = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: ${colors.background};
  flex-direction: center;
  align-items: center;
  justify-content: space-evenly;
  transition-duration: 0.3s;
  &.openPreview {
    opacity: 1;
    visibility: visible;
  };
  &.closePreview {
    opacity: 0;
    visibility: hidden;
  }
  &.openPreview2 {
    opacity: 1;
    visibility: visible;
  };
  &.closePreview2 {
    opacity: 0;
    visibility: hidden;
  }
`

export const TitlePreview = styled.h2`
  text-align: center;
  font-size: 1rem;
  font-weight: 300;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const Divider = styled.div`
  height: 2px;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 5px;
  width: 35px;
  background: ${colors.redWaskoll};
`

export const WrapperIconView = styled.div`
  position: absolute;
  right: 50px;
  top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TitleIcon = styled.span`
  font-size: 1rem;
  margin: 0;
  color: ${colors.softGrey};
`

export const ButtonViewCollection = styled.button`
  display: flex;
  font-family: Lora;
  cursor: pointer;
  border: 0;
  padding: 10px 15px;
  background: ${colors.softBeige};
  border-radius: .5rem;
  align-items: center;
  justify-content: center;
  color: ${colors.pinkDark};
  margin: 0;
  margin-top: 20px;
  outline: none;
  font-size: 1rem;
  transition-duration: 0.2s;
  &:hover{
    background: ${colors.beige};
    color: black;
  }
`

export const GatsbyImageWrapper = styled.div`
  width: 150px;
  @media (max-width: ${breakpoints.tablet}px) {
    max-width: 17vw;
  }
`

export const ImageFeatured = styled.img`
  max-width: 150px;
  max-height: 165px;
  @media (max-width: ${breakpoints.tablet}px) {
    max-width: 17vw;
  }
`

export const ImageFeaturedCol = styled.div`
  width: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 300px;
  @media (max-width: ${breakpoints.tablet}px) {
    max-width: 17vw;
  }
`
