import React from "react"
import "./styles.css"
import { Carousel } from "react-responsive-carousel"
import {
  TitleSlide,
  DescriptionSlide,
  WrapperSlider, ButtonSlide, WrapperContent, ImageSliderMobile, ImageSliderDesktop
} from "./StyledSlider"
import "./styles.css"
import Img from "gatsby-image"

export default React.memo(({data}) => {

  return (
    <WrapperSlider>
      <Carousel autoPlay infiniteLoop showIndicators={false} showThumbs={false} showStatus={false}>
        {data.length > 0 && data.map(slide => (
          <div key={slide.id}>
            <ImageSliderDesktop
              width={1850}
              height={925}
              alt={""}
              src={slide.imageDesktop.localFile.childImageSharp.fluid.src}
              srcSet={slide.imageDesktop.localFile.childImageSharp.fluid.srcSet}
            />
            <ImageSliderMobile
              width={1850}
              height={925}
              alt={""}
              src={slide.imageMobile.localFile.childImageSharp.fluid.src}
              srcSet={slide.imageMobile.localFile.childImageSharp.fluid.srcSet}
            />
{/*
            <Img placeholderStyle={{ visibility: "hidden" }} fluid={slide.image.localFile.childImageSharp.fluid}/>
*/}
            <WrapperContent>
              <TitleSlide>
                {slide.title}
              </TitleSlide>
              <DescriptionSlide>
                {slide.subTitle}
              </DescriptionSlide>
              <ButtonSlide>
                {slide.ctaTitle}
              </ButtonSlide>
            </WrapperContent>
          </div>
        ))}
      </Carousel>
    </WrapperSlider>
  )
}
)


