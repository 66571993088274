import styled from "@emotion/styled"
import { colors, animationDuration, breakpoints, dimensions } from "../styles"

export const FeaturedImageBlockWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: ${dimensions.contentWidth};
  margin: auto;
  @media (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  @media (max-width: ${breakpoints.tablet}px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`

export const ImageContainer = styled.img`
  @media (max-width: ${breakpoints.tablet}px) {
    width: 100%;
  }
`

export const DescriptionWrapper = styled.div`
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  padding: 50px;
  width: 50%;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  @media (max-width: ${breakpoints.tablet}px) {
    width: 100%;
    padding: 20px;
    line-height: 2.1rem;
  }
`

export const ButtonForm = styled.button`
  display: flex;
  font-family: Lora;
  cursor: pointer;
  border: 0;
  padding: 10px 13px;
  background: ${colors.softBeige};
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  color: ${colors.darkWaskoll};
  outline: none;
  font-size: 1rem;
  width: 200px;
  margin-top: 3rem;
  transition-duration: 0.2s;
  &:hover{
    background: ${colors.beige};
    color: black;
  };
  @media (max-width: ${breakpoints.tablet}px) {
    margin-top: 50px;
  }
`
