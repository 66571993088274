import React, { useEffect, useState } from "react"

import { FaEye } from "react-icons/fa"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import {
  FeaturedImageBlockWrapper,
  ImageWrapper,
  DescriptionWrapper,
  ButtonForm,
  ImageContainer
} from "./StyledFeaturedWedding"

export default () => {
  const [isContactFormVisible, setContactForm] = useState(0)

  const toggleContactForm = (e) => {
    e.preventDefault()
    setContactForm(!isContactFormVisible)
  }

  const imageSrc = useStaticQuery(graphql`
      query {
          weeding: file(relativePath: { eq: "HOMEPAGE_FEATURED_MARIAGE.jpeg" }) {
              childImageSharp {
                  fluid(maxHeight: 400) {
                      ...GatsbyImageSharpFluid
                  }
              }
          },

      }
  `)

  return (
    <FeaturedImageBlockWrapper>
      <ImageWrapper>
        <ImageContainer width={500} height={333} alt={""} src={imageSrc.weeding.childImageSharp.fluid.src} />
      </ImageWrapper>
      <DescriptionWrapper>
        <p>En ce jour heureux, nous fusionnons, nos doigts s’entrelacent
        pour ne former qu’un. Je prends ta taille fine et t’attire vers moi
        succombant à ton élégance insolente et ton charme inné.</p>
        <p>
          Vous souhaitez faire la plus belle proposition à celle qui sera votre moitié?
        </p>
        <p>
          Laissez-nous vous guider dans la décision la plus importante de votre vie.
        </p>
        <ButtonForm>
          <span style={{marginLeft: "10px"}}>Prendre Rendez-vous</span>
        </ButtonForm>
      </DescriptionWrapper>
    </FeaturedImageBlockWrapper>
  )
}
