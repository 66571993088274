import React, { useEffect, useState } from "react"

import { FaEye } from "react-icons/fa"


import {
  ImageService,
  ImageWrapper,
  ServicesOfferedContainer,
  ServiceWrapper, TitleService,
  TitleWrapper, ButtonForm, WrapperColumn, Divider, ServicesOfferedWrapper, TitleContainer
} from "./StyledServicesOffered"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export default () => {
  const [hoverZoom, setHoverZoom] = useState(0)

  const imageSrc = useStaticQuery(graphql`
      query {
          IMAGE1: file(relativePath: { eq: "SO-1.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid
                  }
              }
          },
          IMAGE2: file(relativePath: { eq: "SO-2.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid
                  }
              }
          },
          IMAGE3: file(relativePath: { eq: "SO-3.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid
                  }
              }
          },
          IMAGE4: file(relativePath: { eq: "SO-4.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid
                  }
              }
          },

      }
  `)

  return (
    <ServicesOfferedWrapper>
      <TitleContainer>SERVICES OFFERTS</TitleContainer>
      <Divider/>
      <ServicesOfferedContainer>
        <ServiceWrapper>
          <ImageWrapper>
            <Img fluid={imageSrc.IMAGE1.childImageSharp.fluid}/>
          </ImageWrapper>
          <TitleWrapper>
            Création sur mesure
            <Divider/>
          </TitleWrapper>
        </ServiceWrapper>
        <ServiceWrapper>
          <ImageWrapper>
            <Img fluid={imageSrc.IMAGE2.childImageSharp.fluid}/>
          </ImageWrapper>
          <TitleWrapper>
            <TitleService>Entretien de vos bijoux</TitleService>
            <Divider/>
          </TitleWrapper>
        </ServiceWrapper>
        <ServiceWrapper>
          <TitleWrapper>
            <TitleService>Mise à taille et gravure</TitleService>
            <Divider/>
          </TitleWrapper>
          <ImageWrapper>
            <Img fluid={imageSrc.IMAGE3.childImageSharp.fluid}/>
          </ImageWrapper>
        </ServiceWrapper>
        <ServiceWrapper>
          <TitleWrapper>
            Devis transformation
            <Divider/>
          </TitleWrapper>
          <ImageWrapper>
            <Img fluid={imageSrc.IMAGE4.childImageSharp.fluid}/>
          </ImageWrapper>
        </ServiceWrapper>
        <ButtonForm>
          <span style={{ marginLeft: "10px" }}>Contactez-nous</span>
        </ButtonForm>
      </ServicesOfferedContainer>
    </ServicesOfferedWrapper>

  )
}
