import React from "react"
import Slider from "../components/Slider"

import ServicesOffered from "../components/ServicesOffered"
import FeaturedWedding from "../components/FeaturedWedding"
import FeaturedCollection from "../components/FeaturedCollection"

import styled from "@emotion/styled"
import { breakpoints } from "../components/styles"

export const MainWrapper = styled.div`
  width: 100%;
  max-width: 1350px;
  margin: auto;
  @media (min-width: ${breakpoints.tablet}px) {
    width: 100%;
  }
`
export const BlockContainer = styled.div`
  margin-top: 80px;
`

const IndexPage = ({pageContext}) => {
  console.info("MAIN RENDER")
  return (
    <>
      <Slider data={pageContext.slider} />
      <MainWrapper>
        <FeaturedCollection data={pageContext.featuredCollection}/>
        <BlockContainer>
          <FeaturedWedding/>
        </BlockContainer>
        <BlockContainer>
          <ServicesOffered/>
        </BlockContainer>
      </MainWrapper>
    </>
  )
}

export default IndexPage