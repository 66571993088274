import React, { useEffect, useState } from "react"
import {
  CollectionWrapper,
  DescriptionWrapper,
  WrapperLeft,
  MetaCollection,
  WrapperRight,
  ShortDescriptionCollection,
  TitleCollection,
  ImageThumb,
  WrapperImage,
  WrapperPreview,
  ImagePreview,
  Divider,
  TitlePreview,
  ButtonViewCollection,
  FeaturedCollectionWrapper,
  WrapperImageCol,
  ImageThumbCol,
  ImageFeaturedCol,
  DescriptionCollection, GatsbyImageWrapper, ImagePreviewWrapper, ImageProduct, ImageProductPreview
} from "./StyledFeaturedCollection"

import { FaEye } from "react-icons/fa"

import { createProductLink } from "../../utils/productHelpers"
import { navigate } from "gatsby"

import Img from "gatsby-image"
import { ImageSlider } from "../Slider/StyledSlider"

export default ({data}) => {
  const [currentPreview, setCurrentPreview] = useState(false)
  const [currentPreview2, setCurrentPreview2] = useState(false)

  const handleOpenPreview = (id) => {
    setCurrentPreview(id)
  }

  const handleClosePreview = () => {
    if (currentPreview) setCurrentPreview(false)
  }

  const handleOpenPreview2 = (e, id) => {
    e.preventDefault()
    setCurrentPreview2(id)
  }

  const handleClosePreview2 = () => {
    if (currentPreview2) setCurrentPreview2(false)
  }

  const handleViewItem = (e, product) => {
    e.preventDefault()
    product.collection = data.title
    let productUrl = createProductLink(product)
    navigate(`/${productUrl}`)
  }

  return (
    <FeaturedCollectionWrapper>
      {data && (
        <CollectionWrapper>
          <WrapperLeft>
            <WrapperPreview onMouseLeave={handleClosePreview}
                            className={currentPreview ? "openPreview" : "closePreview"}>
              <ImagePreview onClick={(e) => handleViewItem(e, currentPreview)}>
                <ImagePreviewWrapper>
                  {currentPreview.images &&
                  <ImageProductPreview
                    width={200}
                    height={200}
                    src={currentPreview.images[0].small.localFile.childImageSharp.fluid.src}
                    srcSet={currentPreview.images[0].small.localFile.childImageSharp.fluid.srcSet}
                    sizes={currentPreview.images[0].small.localFile.childImageSharp.fluid.sizes}
                    alt={""}
                  />}
                </ImagePreviewWrapper>
                <TitlePreview>{currentPreview.attributeType} {currentPreview.attributeMetal} {currentPreview.attributeGem}</TitlePreview>
              </ImagePreview>
            </WrapperPreview>
            <MetaCollection>
              <TitleCollection>Collection {data.title}</TitleCollection>
              <Divider/>
              <ShortDescriptionCollection>
                {data.subTitle}
              </ShortDescriptionCollection>
            </MetaCollection>
            <WrapperImage>
              <ImageThumb
                onMouseEnter={() => handleOpenPreview(data.products[0])}>
                <GatsbyImageWrapper>
                  <ImageProduct
                    width={150}
                    height={150}
                    src={data.products[0].images[0].small.localFile.childImageSharp.fluid.src}
                    srcSet={data.products[0].images[0].small.localFile.childImageSharp.fluid.srcSet}
                    sizes={data.products[0].images[0].small.localFile.childImageSharp.fluid.sizes}
                    alt=""
                  />
{/*
                  <Img placeholderStyle={{ visibility: "hidden" }} fluid={data.products[0].images[0].small.localFile.childImageSharp.fluid} />
*/}

                </GatsbyImageWrapper>
              </ImageThumb>
              <ImageThumb
                onMouseEnter={() => handleOpenPreview(data.products[1])}>
                <GatsbyImageWrapper>
                  <ImageProduct
                    width={150}
                    height={150}
                    alt=""
                    src={data.products[1].images[0].small.localFile.childImageSharp.fluid.src}
                    srcSet={data.products[1].images[0].small.localFile.childImageSharp.fluid.srcSet}
                    sizes={data.products[1].images[0].small.localFile.childImageSharp.fluid.sizes}
                  />
                  {/*<Img placeholderStyle={{ visibility: "hidden" }} fluid={data.products[1].images[0].small.localFile.childImageSharp.fluid} />*/}
                </GatsbyImageWrapper>
              </ImageThumb>
            </WrapperImage>
          </WrapperLeft>
          <WrapperRight>
            <WrapperPreview onMouseLeave={handleClosePreview2}
                            className={currentPreview2 ? "openPreview2" : "closePreview2"}>
              <ImagePreview onClick={(e) => handleViewItem(e, currentPreview2)}>
                <ImagePreviewWrapper>
                  {currentPreview2.images &&
                  <ImageProductPreview
                    width={200}
                    height={200}
                    alt={""}
                    src={currentPreview2.images[0].small.localFile.childImageSharp.fluid.src}
                    srcSet={currentPreview2.images[0].small.localFile.childImageSharp.fluid.srcSet}
                    sizes={currentPreview2.images[0].small.localFile.childImageSharp.fluid.sizes}
                  />}
                </ImagePreviewWrapper>
                <TitlePreview>{currentPreview2.attributeType} {currentPreview2.attributeMetal} {currentPreview2.attributeGem}</TitlePreview>
              </ImagePreview>
            </WrapperPreview>
            <WrapperImageCol>
              <ImageThumbCol onMouseEnter={(e) => handleOpenPreview2(e, data.products[2])}>
                <ImageFeaturedCol>
                  <GatsbyImageWrapper>
                    <ImageProduct
                      width={150}
                      height={150}
                      src={data.products[2].images[0].small.localFile.childImageSharp.fluid.src}
                      srcSet={data.products[2].images[0].small.localFile.childImageSharp.fluid.srcSet}
                      sizes={data.products[2].images[0].small.localFile.childImageSharp.fluid.sizes}
                    />
{/*
                    <Img placeholderStyle={{ visibility: "hidden" }} fluid={data.products[2].images[0].small.localFile.childImageSharp.fluid} />
*/}
                  </GatsbyImageWrapper>
                </ImageFeaturedCol>
              </ImageThumbCol>
            </WrapperImageCol>
            <WrapperImageCol>
              <ImageThumbCol onMouseEnter={(e) => handleOpenPreview2(e, data.products[3])}>
                <ImageFeaturedCol>
                  <GatsbyImageWrapper>
                    <ImageProduct
                      width={150}
                      height={150}
                      alt=""
                      src={data.products[3].images[0].small.localFile.childImageSharp.fluid.src}
                      srcSet={data.products[3].images[0].small.localFile.childImageSharp.fluid.srcSet}
                      sizes={data.products[3].images[0].small.localFile.childImageSharp.fluid.sizes}
                    />
{/*
                    <Img placeholderStyle={{ visibility: "hidden" }} fluid={data.products[3].images[0].small.localFile.childImageSharp.fluid} />
*/}
                  </GatsbyImageWrapper>
                </ImageFeaturedCol>
              </ImageThumbCol>
              <ImageThumb
                onMouseEnter={(e) => handleOpenPreview2(e, data.products[4])}>
                <GatsbyImageWrapper>
                  <ImageProduct
                    alt=""
                    width={150}
                    height={150}
                    src={data.products[4].images[0].small.localFile.childImageSharp.fluid.src}
                    srcSet={data.products[4].images[0].small.localFile.childImageSharp.fluid.srcSet}
                    sizes={data.products[4].images[0].small.localFile.childImageSharp.fluid.sizes}
                  />
{/*
                  <Img placeholderStyle={{ visibility: "hidden" }} fluid={data.products[4].images[0].small.localFile.childImageSharp.fluid} />
*/}
                </GatsbyImageWrapper>
              </ImageThumb>
            </WrapperImageCol>
          </WrapperRight>
        </CollectionWrapper>
      )}
      <DescriptionWrapper>
        <DescriptionCollection>
          {data.description}
        </DescriptionCollection>
        <ButtonViewCollection>
          <FaEye/>
          <span style={{ marginLeft: "10px" }}>Découvrir les créations</span>
        </ButtonViewCollection>
      </DescriptionWrapper>
    </FeaturedCollectionWrapper>
  )
}


